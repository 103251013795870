import React, { Component } from "react";
import "./App.css";
import { HomePage, GamePage } from "./pages";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { BoardGameContainer } from "./components";

class App extends Component {
  render() {
    return (
      <BoardGameContainer>
        <Router>
          <>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/games/:gameId" component={GamePage} />
          </>
        </Router>
      </BoardGameContainer>
    );
  }
}

export default App;
