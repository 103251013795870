import React, { Component } from "react";
import { Select, Button, Row } from "antd";
import { gameData } from "../data";
import { BoardGamesContext } from "./board-game.container";

export class SearchBar extends Component<
  { history: any },
  { selectedValue: any }
> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedValue: undefined
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  handleOnChange(gameId: any) {
    this.setState({ selectedValue: gameId });
  }

  handleOnClick() {
    this.props.history.push(`/games/${this.state.selectedValue}`);
  }

  render() {
    return (
      <BoardGamesContext.Consumer>
        {contextValue => {
          const { gamesArray } = contextValue;
          return (
            <>
              <Select
                defaultValue={this.state.selectedValue}
                placeholder="Search for a Game"
                style={{ width: "50%", height: "40px" }}
                showSearch
                size={"large"}
                onChange={this.handleOnChange}
                filterOption={(inputValue, option: any) => {
                  const optionString = option.props.children.toLowerCase();
                  const seatchText = inputValue.toLowerCase();

                  return optionString.includes(seatchText);
                }}
              >
                {gamesArray.map((gameData: any) => {
                  return (
                    <Select.Option
                      key={gameData.guid}
                      value={gameData.board_game_id}
                    >
                      {gameData.bg_title}
                    </Select.Option>
                  );
                })}
              </Select>{" "}
              <Button
                size="large"
                disabled={!this.state.selectedValue}
                onClick={this.handleOnClick}
                style={{
                  backgroundColor: "#CC5E0E",
                  borderColor: "#CC5E0E",
                  color: "white"
                }}
              >
                Go
              </Button>
            </>
          );
        }}
      </BoardGamesContext.Consumer>
    );
  }
}
