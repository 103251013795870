import React, { Component } from "react";
import { Layout } from "../layouts";
import { Row, H1, Col, Select, BoardGamesContext } from "../components";
import YouTube from "react-youtube";
import { gameData } from "../data";
import { IBoardGame } from "../types";

export const GamePage = ({ match }: any) => {
  return (
    <BoardGamesContext.Consumer>
      {contextValue => {
        const { gamesDictionary } = contextValue;
        const { gameId } = match.params;
        const chosenGame: IBoardGame = gamesDictionary.get(+gameId) || {
          board_game_id: 1,
          bg_title: "",
          date_created: "",
          date_updated: "",
          guid: "",
          youtube_id: ""
        };

        return (
          <Layout>
            <Row type="flex" justify="center">
              <Col>
                <H1> {chosenGame.bg_title} </H1>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col>
                <YouTube
                  videoId={chosenGame.youtube_id} // defaults -> null
                  // id={string} // defaults -> null
                  // className={string} // defaults -> null
                  // containerClassName={string} // defaults -> ''
                  // opts={obj} // defaults -> {}
                  // onReady={func} // defaults -> noop
                  // onPlay={func} // defaults -> noop
                  // onPause={func} // defaults -> noop
                  // onEnd={func} // defaults -> noop
                  // onError={func} // defaults -> noop
                  // onStateChange={func} // defaults -> noop
                  // onPlaybackRateChange={func} // defaults -> noop
                  // onPlaybackQualityChange={func} // defaults -> noop
                />
              </Col>
            </Row>
          </Layout>
        );
      }}
    </BoardGamesContext.Consumer>
  );
};
