import React from "react";
import styled from "styled-components";
// import img from "./gamepagebacksplash1.jpg";
import mobileImage from "./back-splash-mobile.jpg";
import tabletImage from "./back-splash-tablet.jpg";
import { DEVICE, SIZE_WIDTH, SIZE_HEIGHT } from "../media-query";

export const Layout = styled.div`
  text-align: center;
  width: 100%;
`;

// export const Layout = ({ children }: any) => <div>{children}</div>;
