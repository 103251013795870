import React from "react";
import styled from "styled-components";
// import img from "./gamepagebacksplash1.jpg";

export const H1 = styled.h1`
  font-family: "Righteous", "Serif";
  color: white;
  font-size: 72pt;
  word-wrap: break-all;
`;

export const H2 = styled.h1`
  font-family: "Righteous", "Serif";
  color: white;
`;
