import React from "react";
import { IBoardGame } from "../types";

type BoardGameDictionary = Map<number, IBoardGame>;

export const BoardGamesContext = React.createContext<IBoardGameContainerState>({
  gamesDictionary: new Map(),
  gamesArray: []
});

export interface IBoardGameContainerState {
  gamesDictionary: BoardGameDictionary;
  gamesArray: IBoardGame[];
}

const produceState = (gamesArray: IBoardGame[]) => {
  const gamesDictionary = gamesArray
    .sort((a, b) => a.bg_title.localeCompare(b.bg_title))
    .reduce((dictionary: BoardGameDictionary, bg: IBoardGame) => {
      dictionary.set(bg.board_game_id, bg);
      return dictionary;
    }, new Map());
  return { gamesArray, gamesDictionary };
};

const gameDataKey = "GAME_DATA";

interface ILocalStorageState {
  gamesArray: IBoardGame[];
  time: number | null;
}

export class BoardGameContainer extends React.Component<
  {},
  IBoardGameContainerState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      gamesDictionary: new Map(),
      gamesArray: []
    };
  }

  getData() {
    return fetch(
      "https://evkzab6ygc.execute-api.us-east-1.amazonaws.com/default/game-list"
    ).then(response => {
      return response.json();
    });
  }

  setLocalStorage(gamesArray: IBoardGame[]) {
    localStorage.setItem(
      gameDataKey,
      JSON.stringify({
        gamesArray,
        time: new Date().getTime()
      })
    );
  }

  getLocalStorage(): ILocalStorageState {
    const gameData = localStorage.getItem(gameDataKey);
    if (gameData) {
      return JSON.parse(gameData);
    } else {
      return {
        gamesArray: [],
        time: null
      };
    }
  }

  componentDidMount() {
    const cacheData = this.getLocalStorage();
    const gamesDontExist = cacheData.gamesArray.length === 0;

    const cacheExpired = !cacheData.time
      ? false
      : new Date().getTime() - cacheData.time > 24 * 3600000;

    if ([gamesDontExist, cacheExpired].includes(true)) {
      this.getData()
        .then(produceState)
        .then(({ gamesDictionary, gamesArray }) => {
          this.setState({
            gamesDictionary,
            gamesArray
          });
          this.setLocalStorage(gamesArray);
        })
        .catch(console.error);
    } else {
      const { gamesDictionary, gamesArray } = produceState(
        cacheData.gamesArray
      );
      this.setState({
        gamesDictionary,
        gamesArray
      });
    }
  }

  render() {
    const { gamesDictionary, gamesArray } = this.state;
    return (
      <BoardGamesContext.Provider value={{ gamesDictionary, gamesArray }}>
        {this.props.children}
      </BoardGamesContext.Provider>
    );
  }
}
