import React, { Component } from "react";
import { Layout } from "../layouts";
import { Row, Col, H1, SearchBar } from "../components";
import { gameData } from "../data";
import { ThemeButton } from "../components/button";
import { Link } from "react-router-dom";

export const HomePage = ({ history }: any) => {
  return (
    <Layout>
      <Row>
        <Row>
          <Col>
            <H1> Learn Board Games </H1>
          </Col>
        </Row>
        <Row>
          <Col>
            <SearchBar history={history} />
          </Col>
        </Row>
      </Row>
    </Layout>
  );
};
